.container {
  width: auto;
  margin-right: 30px;
  display: block;
}

img#dialog-image {
  height: 81vh;
  width: 81vw;
  object-fit: contain;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-gallery > li {
  flex: 1 1 auto;
  height: 350px;
  cursor: pointer;
  position: relative;
  list-style-type: none;
}

.image-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.image-gallery::after {
  content: "";
  flex-grow: 4;
}
